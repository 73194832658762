import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import Button from 'components/common/Button';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import HubLinks from './components/HubLinks';
import HubVideoCard from './components/HubVideoCard';
import HubRecommended from './components/HubRecommended';
import HubArticleCard from './components/HubArticleCard';
import { IHubContentSectionProps } from './model';

import './HubContentSection.scss';

const HubContentSection: FC<IHubContentSectionProps> = ({
  title,
  description,
  links,
  hubItems,
  bottomLink,
  sectionBg,
  sectionTheme,
  sectionAnchor,
  hubRecommended,
}) => {
  const bgColor = sectionBg?.[0]?.properties?.colorPicker?.label;
  const { url, name, target, queryString } = bottomLink?.[0] || {};
  const hubRecommendedData = hubRecommended?.[0]?.properties;

  return (
    <section
      id={sectionAnchor}
      className={classnames('hub-content', {
        [`${bgColor}-default-bg`]: bgColor,
        [`hub-content--${sectionTheme}`]: sectionTheme,
      })}
    >
      <div className="hub-content__container">
        <DangerouslySetInnerHtml className="hub-content__title" html={title} />
        <DangerouslySetInnerHtml className="hub-content__description" html={description} />
        {links?.length ? <HubLinks links={links} /> : null}
        <div
          className={classnames('hub-content__items-wrapper', {
            'hub-content__items-wrapper--with-recommended': hubRecommendedData,
          })}
        >
          <ul className="hub-content__items">
            {hubItems.map(({ properties }) => (
              <li key={properties.title} className="hub-content__item">
                {'videoLink' in properties && properties.videoLink ? (
                  <HubVideoCard {...properties} />
                ) : (
                  <HubArticleCard {...properties} />
                )}
              </li>
            ))}
          </ul>
          {hubRecommendedData ? <HubRecommended {...hubRecommendedData} /> : null}
        </div>
        {url ? (
          <Button
            className="hub-content__bottom-link"
            link={url + (queryString || '')}
            target={target}
            ariaLabel={name}
          >
            {name}
          </Button>
        ) : null}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentHubContentSection on IHubContentSection {
    title
    description
    links {
      properties {
        icon {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fixed(width: 20, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          svg {
            content
          }
        }
        iconAlt
        link {
          name
          url
          target
        }
      }
    }
    bottomLink {
      name
      target
      url
      queryString
    }
    sectionBg {
      properties {
        colorPicker {
          label
        }
      }
    }
    hubItems {
      properties {
        title
        description
        image {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        imageAlt
        link {
          url
          target
        }
        playIcon {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fixed(width: 50, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          svg {
            content
          }
        }
        playBtnAriaLabel
        videoLink
        tags {
          value
        }
      }
    }
    hubRecommended {
      properties {
        title
        features {
          properties {
            text
            icon {
              svg {
                content
              }
            }
            bgColor {
              properties {
                colorPicker {
                  label
                }
              }
            }
          }
        }
        recommendedProduct {
          properties {
            label
            labelBgColor {
              properties {
                colorPicker {
                  label
                }
              }
            }
            image {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fixed(width: 250, quality: 90) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            imageAlt
            title
            description
            link {
              name
              url
              target
            }
            linkBgColor {
              properties {
                colorPicker {
                  label
                }
              }
            }
          }
        }
      }
    }
    sectionTheme
    sectionAnchor
  }
`;

export default HubContentSection;
